form .form-group .ng-select span,
form .form-group .ng-select input {
  margin-top: 0;
  margin-bottom: 0; }

.ng-select.ng-select-disabled.readonly {
  opacity: 1; }
  .ng-select.ng-select-disabled.readonly .ng-select-container {
    border-bottom: none;
    box-shadow: none; }
    .ng-select.ng-select-disabled.readonly .ng-select-container .ng-value-container .ng-placeholder {
      display: none; }
  .ng-select.ng-select-disabled.readonly .ng-arrow-wrapper {
    display: none; }

.ng-select.ng-select-opened > .ng-select-container {
  border-color: #9a9a9a; }
  .ng-select.ng-select-opened > .ng-select-container:hover {
    box-shadow: none; }

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-bottom: 1px solid #0094d2;
  background: linear-gradient(to bottom, transparent calc(100% - 1px), #0094d2 calc(100% - 1px)); }

.ng-select.ng-select-disabled {
  opacity: .5;
  cursor: not-allowed; }

.ng-select .ng-has-value .ng-placeholder {
  display: none; }

.ng-select .ng-select-container {
  margin-top: -1px;
  border-bottom: 1px solid #9a9a9a;
  min-height: 24px;
  align-items: center;
  box-sizing: border-box; }
  .ng-select .ng-select-container:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }
  .ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 6px; }
    .ng-select .ng-select-container .ng-value-container .ng-placeholder {
      color: #777; }
  .ng-select .ng-select-container .ng-value {
    flex: 1;
    display: flex; }

.ng-select.ng-select-single {
  /* to avoid problem when use a ng-select in a template of another ng-select */ }
  .ng-select.ng-select-single.ng-select-filtered .ng-select-container .ng-value-container .ng-value {
    visibility: inherit !important; }
  .ng-select.ng-select-single.ng-select-filtered > .ng-select-container > .ng-value-container > .ng-value {
    visibility: hidden !important; }
  .ng-select.ng-select-single .ng-select-container {
    min-height: 24px; }
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
      left: 0;
      top: 0;
      padding-right: 50px;
      display: flex; }
      .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
        padding: 0; }
      .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input:not([disabled]) {
        cursor: pointer; }

.ng-select .ng-clear-wrapper {
  color: #999; }
  .ng-select .ng-clear-wrapper .ng-clear {
    font-size: .7rem !important;
    line-height: 1.4em !important;
    vertical-align: bottom !important;
    padding: 0 .2rem; }
    .ng-select .ng-clear-wrapper .ng-clear:hover {
      color: #c92100; }

.ng-select .ng-spinner-zone {
  padding-right: 5px;
  padding-top: 5px; }

.ng-select .ng-arrow-wrapper {
  padding-right: 5px;
  width: 24px; }
  .ng-select .ng-arrow-wrapper .ng-arrow {
    content: "";
    height: .41667rem !important;
    width: .41667rem !important;
    background-image: url('data:image/svg+xml;charset=utf8,<svg%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20viewBox%3D"0%200%2012%2012">%0A%20%20%20%20<defs>%0A%20%20%20%20%20%20%20%20<style>.cls-1%7Bfill%3A%239a9a9a%3B%7D<%2Fstyle>%0A%20%20%20%20<%2Fdefs>%0A%20%20%20%20<title>Caret<%2Ftitle>%0A%20%20%20%20<path%20class%3D"cls-1"%20d%3D"M6%2C9L1.2%2C4.2a0.68%2C0.68%2C0%2C0%2C1%2C1-1L6%2C7.08%2C9.84%2C3.24a0.68%2C0.68%2C0%2C1%2C1%2C1%2C1Z"%2F>%0A<%2Fsvg>%0A');
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin: 0; }

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #9a9a9a;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }
  .ng-dropdown-panel.ng-select-bottom {
    top: 101%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: 0; }
    .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px; }
  .ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: 0; }
    .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px; }
  .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #9a9a9a;
    padding: 5px 7px; }
  .ng-dropdown-panel .ng-dropdown-footer {
    border-bottom: 1px solid #9a9a9a;
    padding: 5px 7px; }
  .ng-dropdown-panel .ng-dropdown-panel-items {
    margin-bottom: 1px; }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
      user-select: none;
      cursor: default;
      padding: 4px 6px; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-select-disabled {
        color: rgba(0, 0, 0, 0.54); }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
        background-color: #ebf5ff;
        color: #333; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
        color: #333;
        background-color: #D9E4EA;
        font-weight: 600; }
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      padding: 4px 6px;
      white-space: normal !important;
      line-height: 1.5em; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        color: #333;
        background-color: #D9E4EA; }
        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
          font-weight: 600; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: #ebf5ff;
        color: #333; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
        color: #cccccc; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
        padding-left: 22px; }
      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400; }
