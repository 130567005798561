/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
body {
  margin: 0; }

.main-container {
  position: relative;
  overflow: hidden; }

.main-container .content-container .content-area {
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column; }

@media screen and (max-width: 800px) {
  .main-container .header-nav.clr-nav-level-1 {
    position: absolute;
    height: initial; } }

@media screen and (max-width: 800px) {
  .main-container .header-hamburger-trigger, .main-container .header-overflow-trigger {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0 0 0.166667rem 0;
    text-align: center;
    white-space: nowrap;
    color: #fafafa;
    opacity: 0.65; }
  .main-container .header .header-hamburger-trigger + .branding {
    padding-left: 0; }
  .main-container .header .branding {
    max-width: 10rem;
    min-width: 0;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; } }

a, button {
  text-decoration: none;
  color: inherit;
  outline: 0; }

a:hover, a:active, a:focus,
button:hover, button:active, button:focus {
  outline: 0; }

a:focus, button:focus {
  outline: 1px dotted #181818; }

.btn.btn-dark {
  color: #002538;
  border-color: #002538; }

.btn.btn-dark:hover {
  background-color: #ebebeb; }

.btn.btn-dark:active {
  box-shadow: 0 2px 0 0 #696969 inset; }

header.header-6, .header.header-6 {
  background-color: #fafafa; }

header .header-nav .nav-link, .header .header-nav .nav-link {
  color: #2c363d; }

.header-hamburger-trigger > span, .header-hamburger-trigger > span::before, .header-hamburger-trigger > span::after {
  background: #ea5b0c; }

.open-hamburger-menu .nav-link {
  font-size: 0.75rem;
  line-height: 2.5rem;
  height: 2.5rem; }

.open-hamburger-menu .ng-select {
  font-size: 0.75rem;
  line-height: 2.5rem;
  height: 2.5rem; }

.btn.btn-link, .btn-link .btn {
  color: #2c363d; }

.btn {
  border-color: #ea5b0c;
  background-color: transparent;
  color: #be4808; }

.btn:active {
  box-shadow: 0 2px 0 0 #ea5b0c inset; }

.btn:visited {
  border-color: #ea5b0c;
  background-color: transparent;
  color: #be4808; }

input[type=text]:not([readonly]), input[type=password]:not([readonly]), input[type=number]:not([readonly]), input[type=email]:not([readonly]), input[type=url]:not([readonly]), input[type=tel]:not([readonly]), input[type=date]:not([readonly]), input[type=time]:not([readonly]), input[type=datetime-local]:not([readonly]) {
  background: linear-gradient(to bottom, transparent 95%, #ea5b0c 95%) no-repeat;
  background-size: 0% 100%; }

.ol-zoom {
  top: inherit;
  bottom: 20px;
  left: 20px; }
  .ol-zoom button {
    height: 1.5rem;
    width: 1.5rem; }

/* Style for legal content here to be apply in innerHTML content */
.legal-content {
  overflow: auto;
  flex: 1; }
  .legal-content h2 {
    color: white;
    font-size: 16pt;
    margin-top: 0; }
  .legal-content h3 {
    color: white;
    font-size: 14pt;
    margin-top: 20px; }
  .legal-content a {
    color: white;
    text-decoration: underline; }
  .legal-content p {
    color: white;
    margin-top: 10px; }
